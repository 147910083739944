import { Modal } from "bootstrap";
import $ from "jquery";

function init() {
    $(".item-modal-opener").on("click", function (e) {
        e.preventDefault();
        var editLink = $(this).data("form");
        var dataType = $(this).data("modal-content-type");
        var blockId = $(this).data("block");
        const myModal = new Modal($("#admin_update_modal"));
        const iframe =
            '<iframe id="update_iframe" name="update_iframe" class="editForm hide" src="' +
            editLink +
            '" height="560" width="100%"></iframe>';

        $("#admin_update_modal")
            .find(".modal-body")
            .html(
                '<div class="loadingScreen"><h1>Loading <i class="fas fa-spinner fa-spin"></i></h1></div>' +
                    iframe
            );
        myModal.show();
        $("#admin_update_modal .close").on("click", function (e) {
            myModal.hide();
            window.location.href = window.location.href;
        });

        $("#update_iframe").on("load", function () {
            $(this).contents().find(".filters #country").parent().hide();
            $(this).contents().find("#global-header").hide();
            // console.log(blockId);
            if (dataType == "block") {
                var blocks = blockId.toString().split(",");
                $(this)
                    .contents()
                    .find(".ni_blocks .ni_block")
                    .each(function () {
                        var neoId = $(this).data("neo-b-id").toString();
                        if (!blocks.includes(neoId)) {
                            $(this).hide();
                        }
                    });
            }

            $(this).show();
            $(".loadingScreen").remove();
        });
    });

    if($('#announcement-modal').length){
        const announcementModal = new Modal($("#announcement-modal"));
        announcementModal.show();
        $("#announcement-modal .close").on("click", function (e) {
            announcementModal.hide();
        });
    }
}

export default init();